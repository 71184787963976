import React, { useEffect, useState } from "react";
import { CiGlobe, CiMail, CiPhone, CiHome, CiClock1 } from "react-icons/ci";
import { motion } from "framer-motion";

const Salony = () => {
    // const ref = useRef(null)
    // const isInView = useInView(ref)
    // console.log(isInView)

    const variants = {
    initial: {
        opacity:0,
        y:-100
    },
    animate: {
        opacity:1,
        y:0,
        type: "spring",
        stiffness: 3000,
        transition: {
            delay:0.5
        }
    }
  }
  const [salony, setSalony] = useState([]);
  useEffect(() => {
    fetch('/salony.json')
      .then(response => response.json())
      .then(data => setSalony(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);


    function sortTable(table) {
    return table.sort((a, b) => a.localeCompare(b));
  }
  function uniqueTable(table) {
    return [...new Set(table)];
  }

  const wojewodztwa = uniqueTable(
    sortTable(salony.map((item) => item.wojewodztwo))
  );
  return (
    <div className="container mx-auto pt-24 flex flex-col">
      <motion.div 
      variants={variants}
      initial="initial"
      animate="animate"
      whileHover="whileHover"
      className="text-5xl my-10 font-Bodoni">
        NASZE SALONY
      </motion.div>
      {wojewodztwa.map((item, index) => {
        return (
          <div key={index}>
            <div className="">
              <div className="text-2xl my-7 uppercase font-Bodoni border-b border-black pb-1">
                {item}
              </div>

              {/* salony w wojewodztwie */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl-grid-cols-4">
                {salony
                  .filter((adres) => adres.wojewodztwo === item)
                  .map((salon, index) => {
                    let { nazwa, tel, www, mail, adres, miasto, godziny } =
                      salon;
                    return (
                      // salon
                      <div
                       
                        key={index}
                        className="flex flex-col space-y-1 my-10">
                            {/* {console.log(ref)} */}
                        <div className="font-bold">{nazwa}</div>
                        <div className="flex items-center space-x-1 cursor-pointer">
                          <CiHome />
                          <span>{miasto + ", " + adres}</span>
                        </div>
                        <div className="flex items-center space-x-1 cursor-pointer">
                          <CiClock1 />
                          <span>{godziny}</span>
                        </div>
                        <div className="flex items-center space-x-1 cursor-pointer">
                          <CiPhone />
                          <a href={"callto:" + tel}>{tel}</a>
                        </div>
                        <div className="flex items-center space-x-1 cursor-pointer">
                          <CiMail />
                          <a href={"mailto:" + mail}>{mail}</a>
                        </div>
                        <div className="flex items-center space-x-1 cursor-pointer">
                          <CiGlobe />
                          <a href={"https://" + www}>{www}</a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Salony;
