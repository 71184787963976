import React from "react";
import { Link } from "react-router-dom";
import { lozko1, lozko2, lozko3 } from "../assets";

const Lozka = () => {
  return (
    <>
      <div className="flex flex-col space-y-10 pt-24">
        
        <div className="flex flex-col-reverse md:flex-row justify-between items-center bg-[#E3DFD6]">
          <div className="flex flex-1 justify-center flex-col pb-14 md:pb-0">
            <div className="text-center font-Bodoni text-xl md:text-2xl lg:text-3xl mb-4 whitespace-nowrap">
              Gimm'e Volume
            </div>
            <div className="text-center bg-black text-white text-xl px-5 py-2 uppercase mx-auto">
            <Link to = "/lozka/gimmevolume">więcej</Link>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <img className="w-full h-full" src={lozko1} alt="Gimm'e Volume" />
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between items-center bg-[#E3DFD6]">
          <div className="flex flex-1 justify-center flex-col pb-14 md:pb-0">
            <div className="text-center font-Bodoni text-xl md:text-2xl lg:text-3xl mb-4 whitespace-nowrap">
              Simple but noble
            </div>
            <div className="text-center bg-black text-white text-xl px-5 py-2 uppercase mx-auto">
            <Link to = "/lozka/simplebutnoble">więcej</Link>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <img className="w-full h-full" src={lozko2} alt="Siple but Nobel" />
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between items-center bg-[#E3DFD6]">
          <div className="flex flex-1 justify-center flex-col pb-14 md:pb-0">
            <div className="text-center font-Bodoni text-xl md:text-2xl lg:text-3xl mb-4 whitespace-nowrap">
              In love with Design®
            </div>
            <div className="text-center bg-black text-white text-xl px-5 py-2 uppercase mx-auto">
            <Link to = "/lozka/inlovewithdesign">więcej</Link>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <img className="w-full h-full" src={lozko3} alt="In love with boucle" />
          </div>
        </div>

      </div>

      
    </>
  );
};

export default Lozka;
