import React from "react";
import Materac from "../components/Materac";

const Baroque = () => {
  const FOLDER_NAME = "/images/baroque";
  const productName = "Baroque";
  const sectionImgTextContent = [
    "wysokość materaca ok. 30 cm", 
    "materac dostępny w nietypowych rozmiarach",
    "pokrowiec stanowi integralną część materaca i jest pikowany naturalną bawełną",
    "ręcznie wykonany materac z ozdobnymi guzikami",  
  ];
  const desc = "To hołd dla epoki baroku, która słynęła z niezwykłego rozmachu i bogactwa w sztuce i designie. Nasz materac nie tylko nawiązuje do tej niezapomnianej epoki, ale także wprowadza ją do Twojej sypialni w sposób niezwykle wyrafinowany. Detale, takie jak eleganckie guziki i subtelne logo, nadają mu niepowtarzalny charakter. Każdy szczegół jest starannie dopracowany, aby stworzyć efektowny wygląd. Materac ten wyróżnia się solidną budową, co gwarantuje jego trwałość i długotrwały komfort. Jego wysokość sprawia, że stanowi imponujący element w sypialni, podkreślając luksusową atmosferę. Materac ten nie nadaje się do prania. Pozwól sobie na chwilę komfortu w stylu barokowym i odkryj, jak sen może być nie tylko regenerujący, ale także niezwykle luksusowy.";
  const fotoDesc = "Materac Baroque, wkład piankowy."
  const quote =
    "Luksusowy wypoczynek to czas, kiedy możemy oderwać się od codziennego pośpiechu i zanurzyć się w chwilach relaksu i rozkoszy.";

  const build = {
    desc : "Materac Baroque jest wyposażony w trwały wkład piankowy, który jest naszą rekomendacją dla tego modelu. Zapewnia on doskonałe wsparcie dla Twojego ciała i długotrwałą wygodę. Rozumiemy, że każdy klient ma swoje własne preferencje, dlatego dajemy Ci możliwość wyboru wkładu odpowiedniego dla Ciebie. Oprócz standardowego wkładu piankowego, możesz zdecydować się na wkład lateksowy lub sprężynowy.",
    punkts: [
      {
        number: "1",
        desc: "Naturalny lateks Vita Talalay H2/H3",
      },
      {
        number: "2",
        desc: "Komfortowa pianka Lazur",
      },
      {
        number: "3",
        desc: "Antybakteryjna i antygrzybiczna pianka Lateksoidalna",
      },
      {
        number: "4",
        desc: "Wysokoelastyczna pianka Komfort Soft",
      },
      {
        number: "5",
        desc: "Odporna na odkształcenia pianka T35",
      },
    ],
  };
  const sklad = {
    gora: "91% wiskoza, 9% len 360g/m2",
    border: "91% wiskoza, 9% len 211g/m2"
  }

  return (
    <Materac 
    FOLDER_NAME={FOLDER_NAME}
    sectionImgTextContent={sectionImgTextContent}
    name={productName}
    desc={desc}
    fotoDesc={fotoDesc}
    quote={quote} 
    build={build}
    sklad={sklad}/>
  );
};

export default Baroque;
