
import React from "react";
import Materac from "../components/Materac";

const AKissOfTheSunLight = () => {
  const FOLDER_NAME = "/images/aKissOfTheSunlight";
  const productName = "A kiss of the Sunlight";
  const sectionImgTextContent = [
    "wysokość materaca ok. 30 cm", 
    "materac dostępny w nietypowych rozmiarach",
    "pokrowiec z tkaniny stanowi integralną część materaca",
    "pikowany pianką dopasowującą się do ciała",
    "elegancki design",

  ];
  const desc = "Czy marzysz o spokojnym, beztroskim śnie podczas letnich nocy? Nasz materac A Kiss of the Sunlight to wybór, który przeniesie Cię w krainę lekkości, piękna i wygody. Nazwa materaca nawiązuje do przepięknego pokrowca, który przypomina promienie słoneczne. Wyjątkowe wzory w odcieniach beżu są niezwykle subtelne, dodając materacowi delikatności i elegancji. Materac wyróżnia się swoją imponującą wysokością sięgającą niemal 30 centymetrów. To oznacza, że możesz cieszyć się głębokim i luksusowym snem każdej nocy. Materac A Kiss of the Sunlight to nie tylko źródło wygody, ale także wyraz naszej troski o detale. Ozdobny złoty zamek dodaje mu niepowtarzalnego uroku i elegancji. Głębokie pikowanie pianką nadaje puszystości. Każdy element jest dopracowany, abyś mógł cieszyć się zarówno wyjątkowym wyglądem, jak i doskonałym snem. Przenieś się w czasie do beztroskich dni lata, kiedy każdy moment był pełen uroku i ciepła słońca. Dzięki tej wyjątkowej okazji możesz mieć letni nastrój przez cały rok.";
  const fotoDesc = "Materac A kiss of the Sunlight, wkład sprężynowy."
  const quote =
    "Jeśli nie dbasz o swój sen, to Twój sen nie zadba o Ciebie. Sen to klucz do efektywnej pracy i życia pełnego energii.";

  const build = {
    desc : "Materac A Kiss of the Sunlight jest wyposażony w trwały wkład sprężynowy, który jest naszą rekomendacją dla tego modelu. Zapewnia on doskonałe wsparcie dla Twojego ciała i długotrwałą wygodę. Rozumiemy, że każdy klient ma swoje własne preferencje, dlatego dajemy Ci możliwość wyboru wkładu odpowiedniego dla Ciebie. Oprócz standardowego wkładu sprężynowego, możesz zdecydować się na wkład piankowy.",
    punkts: [
      {
        number: "1",
        desc: "Komfortowa pianka Lazur ",
      },
      {
        number: "2",
        desc: "Antybakteryjna i antygrzybiczna pianka Lateksoidalna",
      },
      {
        number: "3",
        desc: "Sprężyna 2000S ",
      },
      {
        number: "4",
        desc: "Odporna na odkształcenia pianka T35",
      },
    ],
  };
  const sklad = {
    gora: "92% wiskoza, 8% włókno konopne 230g/m2 pikowany pianką visco 2cm",
    border: "91% wiskoza, 9% len 211g/m2"
  }

  return (
    <Materac 
    FOLDER_NAME={FOLDER_NAME}
    sectionImgTextContent={sectionImgTextContent}
    name={productName}
    desc={desc}
    fotoDesc={fotoDesc}
    quote={quote} 
    build={build}
    sklad={sklad}/>
  );
};

export default AKissOfTheSunLight;
