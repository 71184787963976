export function getCategoriesList(data) {
  let uniqueValues = [];

  data.forEach((item) => {
    uniqueValues.push(item.name);
    // if (item.addons.length > 0) {
    //   uniqueValues = uniqueValues.concat(item.addons);
    // }
  });

  return [...new Set(uniqueValues)];
}

export function updateRequirementsCategories(element, categories) {
  
  //spradzam, czy element to nie dodatek, jeżeli tak to nie moge modyfikować tej tablicy
  if(categories.find(category => category.name === element.category && category.parentIndex))
  return categories
  
    //usuwam wszystkie kategorie ktore maja name albo parentName taką jak kategoria elementu klikniętego
    let updatedCategories = categories.filter(
    (category) =>
      category.name !== element.category &&
      category.parentName !== element.category
  );
//dodaje nowa kategorie elementu kliknietego
  updatedCategories.push({
    name: element.category,
    parentIndex: null,
    parentName: null,
    // addons: [],
  });
// jezeli element ma dodatki to je dodaje
  if (element.addons) {
    for (const adon of element.addons) {
      updatedCategories.push({
        name: adon,
        parentIndex: element.index,
        parentName: element.category,
        // addons: [],
      });
    }
  }

  return updatedCategories;
}



//do koszyka mozna dodat tylko takie elementy, ktorych kategoria jest w requirementsCategories
export function updateCart(element, cart, updatedRequirementsCategories) {
    const updatedCart = cart
    .map((cartElement) =>
      cartElement.category === element.category ? element : cartElement
    )
    .filter((cartElement) => {
        const requirement = updatedRequirementsCategories.find(reqCat => reqCat.name === cartElement.category);

        // Jeśli nie znaleziono pasującej kategorii wymagań, odfiltruj ten element koszyka
        if (!requirement) return false;

        // Dodatkowa logika dla przypadków, gdy parentIndex jest niepusty
        if (requirement.parentIndex !== null && cartElement.fit && cartElement.fit.length > 0) {
            // Sprawdzanie, czy jakikolwiek element w 'fit' spełnia warunki
            if (!cartElement.fit.some(elem => elem.includes(requirement.parentIndex.toString().substring(0, elem.length))))
            return false;
        


            
        }
   

        return true;
    });


  // Dodanie nowego elementu, jeśli jeszcze go nie ma
  if (!cart.some((cartElement) => cartElement.category === element.category)) {
    if (
      getCategoriesList(updatedRequirementsCategories).includes(
        element.category
      )
    ) {
      updatedCart.push(element);
    }
  }
  return updatedCart;
}

export function getAvailableSize(data) {
  //ustawiamy dostepne rozmiary, tylko takie, ktore wystepuja w lozkach i wkladach i mozna z nich zbudowac komplet
  const availableBuildSize = data
    .filter((element) => element.category === "build")
    .map((element) => element.size);
  const availableBedSize = data
    .filter((element) => element.category === "bed")
    .map((element) => element.size);

  const availableSizeTemp = [
    ...new Set(
      availableBedSize.filter((element) => availableBuildSize.includes(element))
    ),
  ];

  return availableSizeTemp
    .map((number) => number.toString().padStart(3, "0")) // Zamień na string i dodaj '0' jeśli jest to konieczne
    .sort() // Sortuj jako stringi
    .map((str) => parseInt(str)); // Zamień z powrotem na liczby
}

export function isIndexInCart(index, cart) {
  return cart.some((element) => element.index === index);
}

export function rebuildCart(cart) {
  // Znajdowanie elementu o kategorii "build"
  const buildObj = cart.find((element) => element.category === "build");
  const coverObj = cart.find((element) => element.category === "cover");
  const textileObj = cart.find((element) => element.category === "textile");
  const bedObj = cart.find((element) => element.category === "bed");

  // Jeśli nie ma elementu "build", zwróć oryginalny koszyk
  if (!buildObj && !coverObj && !textileObj && !bedObj) {
    return cart;
  }

  // Mapowanie i modyfikacja koszyka
  return cart.map((cartElem) => {
    if (cartElem.category === "build" && coverObj) {
      return {
        ...cartElem,
        name: coverObj.name,
        index: cartElem.index.replace("COVER", coverObj.index),
      };
    } else if (cartElem.category === "bed" && textileObj) {
      return {
        ...cartElem,
        name: cartElem.name + " " + textileObj.name,
        index: cartElem.index + " " + textileObj.index,
      };
    } else {
      return cartElem;
    }
  });
}

export function pricify(number) {
  if (!isNaN(Number(number))) {
    return number.toLocaleString("fr-FR").replace(/,/g, " ") + " zł";
  }
  return "- zł";
}

export function finalPrice(cart) {
  return cart.reduce((total, item) => {
    // Konwersja ceny na liczbę i sprawdzenie, czy jest to poprawna liczba
    if (!isNaN(Number(item.price))) {
      // Dodawanie skonwertowanej wartości ceny do łącznej sumy
      return total + Number(item.price);
    }
    // Jeśli cena nie jest liczbą, kontynuuj z dotychczasową sumą
    return total;
  }, 0); // Początkowa wartość sumy to 0
}

export function getImagesObjListFromFolder(folder) {
  const img = require.context(
    `../assets/`,
    true,
    /\.(png|jpe?g|jpg|svg|webp)$/
  );

  return img
    .keys()
    .filter((key) => key.startsWith(`./${folder}/`))
    .map((key) => {
      const lastSlashIndex = key.lastIndexOf("/");
      const firstDotIndex = key.indexOf(".", lastSlashIndex);
      const name = key.substring(lastSlashIndex + 1, firstDotIndex);
      const imagePath = img(key); // bezpośrednie uzyskanie ścieżki obrazu

      // Struktura obiektu zależy od konfiguracji Webpacka i modułu
      // Zwróć uwagę, że może być konieczne dostosowanie tej linii
      const photo = imagePath.default || imagePath;
      return { name, photo };
    });
}
