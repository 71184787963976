import React from "react";
import Lozko from "../components/Lozko";

const SimpleButNoble = () => {
  const sample = ["Melangerie", "Metis", "Trench"];
  const productName = "Simple but noble";
  const FOLDER_NAME = "/images/simpleButNobel";
  const desc = "Simple but noble to połączenie prostoty i szlachetności. Jego minimalistyczny design czyni go uniwersalnym dodatkiem do każdego wnętrza. Mebel udowadnia, że czasem mniej znaczy więcej. To niezwykłe łóżko, które przyciąga uwagę swoją prostotą i klasyką. Jest to idealny wybór dla tych, którzy doceniają piękno w detalu. Łózko stoi na 8 cm nóżkach i posiada wbudowane sprężyny kieszeniowe, które stanowią elastyczne podparcie dla materaca. To gwarancja wyjątkowego komfortu snu.";
  const dim = {
    h: 128,
    l: 210,
    w: "162/182/202",
  };
  const features = [
    "elegancki, klasyczny zagłówek",
    "korpus typu box z wbudowaną sprężyną 7-polową Punktoflex H3",
    "do wyboru nóżki drewniane 6x6x12 czarne lub brązowe",
    "rekomendowana tkanina: Metis",
    "możliwość zamówienia w nietypowych wymiarach" 

  ]
  const carousel = [
    `${FOLDER_NAME}/colors/1.jpg`,
    `${FOLDER_NAME}/colors/2.jpg`,
    `${FOLDER_NAME}/colors/3.jpg`,
    `${FOLDER_NAME}/colors/4.jpg`,
    `${FOLDER_NAME}/colors/5.jpg`,
    `${FOLDER_NAME}/colors/6.jpg`,
  ]

  return (
    <Lozko
    FOLDER_NAME={FOLDER_NAME}
    name={productName}
    desc={desc}
    dim={dim} 
    sample={sample}
    features={features}
    carousel={carousel}/>
  );
};

export default SimpleButNoble;
