import { useState } from "react";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi2";
import { IoCloseOutline } from "react-icons/io5";
import { ReactComponent as InfoPdf } from "../assets/infoPdf.svg";

const Stone = ({ folder }) => {
  const [index, setIndex] = useState(0);
  const [fs, setFs] = useState(false);
  const [direction, setDirection] = useState(1);

  const img = require.context(
    `../assets/`,
    true,
    /\.(png|jpe?g|jpg|svg|webp)$/
  );
  const images = img
    .keys()
    .filter((key) => key.startsWith(`./${folder}/`))
    .map(img);
  // console.log(images)

 

  // const variants = {
  //   initialN: {
  //     x: 100,
  //     opacity: 1,
  //   },
  //   initialP: {
  //     x: -100,
  //     opacity: 1,
  //   },
  //   animate: {
  //     x: 0,
  //     opacity: 1,
  //     transition: "ease-in",
  //   },
  //   exitN: {
  //     x: -100,
  //     opacity: 0,
  //   },
  //   exitP: {
  //     x: 100,
  //     opacity: 0,
  //   },
  // };

  function next() {
    index === images.length - 1 ? setIndex(0) : setIndex(index + 1);
    setDirection(1);
  }

  function prev() {
    index === 0 ? setIndex(images.length - 1) : setIndex(index - 1);
    setDirection(-1);
  }

  function fullScreen(ind) {
    setFs(!fs);
    setIndex(ind);
  }

  function printName(image) {
    if (image) {
      let lastSlashIndex = image.lastIndexOf("/");
      let firstDotIndex = image.indexOf(".", lastSlashIndex);
      let name = image.substring(lastSlashIndex + 1, firstDotIndex);
      return name;
    } else {
      return " ";
    }
  }

  function splitName(value) {
    if(!value) return {number: '', name: ''}
    
    if (value.includes('_')){
        const parts = value.split('_');
        const number = parts[0];
        const name = parts[1];
        return {number, name}
    }
    else {
      return {number: '', name: value}
    }
  }


  return (
    <div className="relative container mx-auto">
      <div
        className={`${
          fs ? "flex " : "hidden "
        } top-0 right-0 left-0 bottom-0 w-screen h-screen bg-black bg-opacity-80 justify-center items-center z-50 overflow-hidden fixed`}>
        <div className="flex p-10 mx-auto relative w-[800px] h-[800px]">
          <img className="w-full h-full rounded-xl" src={images[index]}></img>
          <div className="bottom-12 right-12 absolute uppercase bg-white bg-opacity-40 rounded-md py-2 px-4 flex items-end justify-center text-3xl flex-col">
            <div className="">{splitName(printName(images[index])).name}</div>
                  <div className="-mb-1">{splitName(printName(images[index])).number}</div>
          </div>
        </div>
        <div
          className="absolute right-8 top-4 cursor-pointer text-white text-2xl opacity-70"
          onClick={fullScreen}>
          <IoCloseOutline />
        </div>
        <div className="absolute top-1/2 translate-y-1/2 w-full px-8 flex justify-between">
          <div className="flex cursor-pointer text-white text-2xl opacity-70" onClick={prev}>
            <HiArrowLeft />
          </div>
          <div className="flex cursor-pointer text-white text-2xl opacity-70" onClick={next}>
            <HiArrowRight />
          </div>
        </div>
        <div className="flex cursor-pointer text-white absolute right-8 bottom-4">
          <div className="w-[70px] h-[70px]">
          {fs && <a href={`./pdf/${clearLink(images[index]
            
          )}`} target="_blank" rel="noopener noreferrer"><InfoPdf /></a>}
          </div>
        </div>
      </div>
      <div className="container flex mx-auto">
        <div className="flex max-w-max w-full h-full flex-wrap justify-center md:justify-start">
          {images.map((image, index) => {
            return (
              <div
                key={index}
                onClick={() => fullScreen(index)}
                className="flex m-2 w-[200px] h-[200px] cursor-pointer relative">
                <img className="rounded-lg shadow-md" src={image}></img>
                <div className="bottom-2 right-2 absolute uppercase bg-white bg-opacity-40 rounded-md py-1 px-2 flex items-end justify-center flex-col leading-none">
                  <div className="">{splitName(printName(image)).name}</div>
                  <div className="text-xs -mb-1">{splitName(printName(image)).number}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Stone;

function clearLink(link) {
  let pattern = /\/static\/media\/(.*?)\.\w+\.jpg/g;
let replacement = "$1.pdf";
  return link.replace(pattern, replacement)
}