import React from 'react'
import Materac from '../components/Materac';

function FrozenDreams() {
    const FOLDER_NAME = "/images/frozenDreams";
    const productName = "Frozen Dreams";
    const sectionImgTextContent = [
      "wysokość materaca ok. 30 cm", 
      "materac dostępny w nietypowych rozmiarach",
"pokrowiec z tkaniny stanowi integralną część materaca",
"pokrowiec zawiera naturalne włókna konopi i lnu",
"pikowany pianką dopasowującą się do ciała",
"elegancki design",

    ];
    const desc = "Jeśli poszukujesz materaca, który doskonale wpasuje się w styl glamour, Frozen Dreams to doskonały wybór. Jego wyjątkowy pokrowiec o delikatnej srebrzystej barwie z siwym zamkiem i elegancką skórką na boku stanowi przeciwieństwo materaca A Kiss of Sunlight. To idealna opcja dla osób, które cenią sobie nowoczesne i stylowe wnętrza. Materac Frozen Dreams zapewnia wyjątkowy komfort i imponującą wysokość, osiągającą niemal 30 centymetrów. Pikowana pianka nadaje mu przyjemną miękkość, zapewniając jednocześnie odpowiednie podparcie. Troska o detale sprawia, że produkt staje się wyjątkowy i spełnia oczekiwania nawet najbardziej wymagających klientów. To recepta na głęboki i spokojny sen.";
    const fotoDesc = "Materac Frozen Dreams, wkład sprężynowy."
    const quote =
      "Praca może dać Ci komfort materialny, ale to sen zapewnia komfort ducha.";
  
    const build = {
      desc : "Materac Frozen Dreams jest wyposażony w trwały wkład sprężynowy, który jest naszą rekomendacją dla tego modelu. Zapewnia on doskonałe wsparcie dla Twojego ciała i długotrwałą wygodę. Rozumiemy, że każdy klient ma swoje własne preferencje, dlatego dajemy Ci możliwość wyboru wkładu odpowiedniego dla Ciebie. Oprócz standardowego wkładu sprężynowego, możesz zdecydować się na wkład piankowy.",
      punkts: [
        {
          number: "1",
          desc: "Komfortowa pianka Lazur ",
        },
        {
          number: "2",
          desc: "Antybakteryjna i antygrzybiczna pianka Lateksoidalna",
        },
        {
          number: "3",
          desc: "Sprężyna 2000S",
        },
        {
          number: "4",
          desc: "Odporna na odkształcenia pianka T35",
        },
      ],
    };
    const sklad = {
      gora: "59% wiskoza, 41% bawełna 240g/m2 pikowany pianką visco 2cm",
      border: "63% wiskoza, 30%poliester, 7% poliamid 376g/m2"
    }
  
    return (
      <Materac 
      FOLDER_NAME={FOLDER_NAME}
      sectionImgTextContent={sectionImgTextContent}
      name={productName}
      desc={desc}
      fotoDesc={fotoDesc}
      quote={quote} 
      build={build}
      sklad={sklad}/>
    );
}

export default FrozenDreams