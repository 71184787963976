import React from 'react'
import { useState } from "react";
import { motion } from "framer-motion";
import Carousel from "./Carousel";
import Stone from "./Stone";
import Divaider from './Divaider';
import { CiCircleChevRight } from "react-icons/ci";


function Lozko({FOLDER_NAME, name={}, dim={}, sample=[], desc={}, carousel=[], features=[]}) {
   const [stone, setStone] = useState(0);

    return (
        <div className="bg-color1">
          <div className="w-full">
            <img
               src={process.env.PUBLIC_URL + FOLDER_NAME + "/main.jpg"}
              alt="zdjecie glowne lozka"></img>
          </div>
          <div className="container mx-auto my-20 justify-center flex">
            <img
              src={process.env.PUBLIC_URL + FOLDER_NAME + "/front.jpg"}
              alt="lozko front"></img>
          </div>
    <Divaider />
    
          <div className="text-center max-w-3xl mx-auto container">
            <div className="uppercase">bed</div>
            <div className="text-4xl font-Bodoni">{name}</div>
            <div className="mt-10">
              {desc}
            </div>
          </div>
          <div className="container mx-auto flex flex-col my-10 items-center md:flex-row">
            <div className="flex-1 flex justify-center p-8">
              <img
                 src={process.env.PUBLIC_URL + FOLDER_NAME + "/bok.jpg"}
                alt="lozko bokiem"></img>
            </div>
            <div className="flex-1 flex flex-col justify-start  p-8 space-y-1 md:border-[#000] md:border-l">
              {/* <div className="uppercase text-xl">cechy charakterystyczne</div> */}
              <div className="uppercase pt-3">cechy charakterystyczne</div>
              {features.map(name => {
                return(
                  <div className="flex items-center space-x-1 pl-2" key={name}>
                      <div><CiCircleChevRight /></div>
                      <div>{name}</div>
                      </div>
                )
              })}
              <div className="uppercase pt-3">wymiary</div>
              <div className="pl-2">wysokość: {dim.h} cm</div>
              <div className="pl-2">długość: {dim.l} cm</div>
              <div className="pl-2">szerokość: {dim.w} cm</div>
              <div className="uppercase pt-3">tkaniny</div>
              {sample.map(name => {
                return(
                    <div className="pl-2" key={name}>seria {name}</div>
                )
              })}
            </div>
          </div>
    
          <div className="container mx-auto my-20 justify-center flex w-full">
            <img
              className="object-fill w-full"
              src={process.env.PUBLIC_URL + FOLDER_NAME + "/wymiarowanie.svg"}
              alt="wymiary lozka"></img>
          </div>
    
          <div className="w-full flex flex-col md:flex-row">
            <div className="flex flex-1">
              <img
              className="object-cover"
                src={process.env.PUBLIC_URL + FOLDER_NAME + "/skos.jpg"}
                alt="lozko skos"></img>
            </div>
            <div className="flex flex-1">
              <div className="flex flex-1">
                <img
                 className="object-cover"
                    src={process.env.PUBLIC_URL + FOLDER_NAME + "/zag.jpg"}
                  alt="lozko zaglowek"></img>
              </div>
              <div className="flex flex-1">
                <img
                 className="object-cover"
                    src={process.env.PUBLIC_URL + FOLDER_NAME + "/rog.jpg"}
                  alt="lozko naroznik"></img>
              </div>
            </div>
          </div>
    
          <div className="container mx-auto my-20">
            <div className="flex uppercase font-Bodoni md:text-xl space-x-2 justify-center md:space-x-8 md:justify-start items-center">
              {sample.map((name, index) => {
                let lastElement = sample.length - 1;
                return (
                  <React.Fragment key={name}>
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      className="cursor-pointer mt-2"
                      onClick={() => setStone(index)}>
                      {name}
                    </motion.div>
                    <div className="text-3xl">
                      {lastElement === index ? "" : "|"}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
    
          {sample.map((name, index) => {
            return (
              <div key={name} className="items-center">
                <div className={(stone === index ? "flex" : "hidden") + ``}>
                  <Stone folder={`img/${name}`} />
                </div>
              </div>
            );
          })}
          <div className="py-24">
            <Carousel arr={carousel} />
          </div>
        </div>
      );
}

export default Lozko