import React from "react";
import {logo} from "../assets";

const Onas = () => (
    <div className="bg-white">
        <div className="container mx-auto flex justify-center items-center h-[1000px] md:h-[800px] p-5">
            <div className="md:flex p-20 hidden"><img className="w-[100px]" src={logo}></img></div>
            <div className="flex flex-1 flex-col text-center space-y-5">
                <h1 className="text-2xl my-3">O NAS</h1>
                <p>Dom to przestrzeń, w której każda osoba ma prawo do odczuwania komfortu i wyjątkowości. Dlatego narodziła się linia Sleep Well - Live Well., oddana trosce o Twój sen i wykwintny styl życia. Jesteśmy ekskluzywną marką, należącą do renomowanego producenta materacy i łóżek M&K foam. SL miała swoją oficjalną premierę na prestiżowych targach w Nadarzynie w roku 2022. Od tego czasu zdobywamy sukcesywnie serca naszych Klientów, którzy doceniają luksus, jakość oraz niepowtarzalny design naszych łóżek i materacy.</p>
                <p>Wierzymy, że głęboki sen jest kluczem do zdrowia i szczęścia, dlatego nasza główna uwaga skupia się na doskonałości i innowacyjności. Naszym celem jest zapewnienie, że nasze produkty dostarczają najwyższego komfortu i wygody oraz stanowią połączenie funkcjonalności, piękna i dostosowania do Twoich osobistych preferencji oraz charakteru. Nasza wyjątkowa dbałość o design i detale pozwala nam tworzyć kolekcje, które wyróżniają się spośród innych produktów na rynku.</p>
                <p>Sieć naszych ekskluzywnych sklepów rozsiana jest po całej Polsce, umożliwiając naszym Klientom dostęp do najwyższej jakości materacy i łóżek. Nasza unikalna kolekcja materacy Sleep well. oraz kolekcja najwyższej klasy łóżek Live well. gwarantują doskonałe wsparcie dla Twojego ciała, co sprawia, że sen staje się jeszcze bardziej odprężający. Nasze łóżka to natomiast synonim stylu i elegancji w każdej sypialni.</p>
                <p>Jesteśmy świadomi, że każdy z nas ma swoje indywidualne potrzeby, dlatego oferujemy także możliwość zamówienia materaca lub łóżka o nietypowych wymiarach. Dzięki temu nasze produkty doskonale dopasowują się do każdej sypialni, spełniając nawet najbardziej wyrafinowane oczekiwania naszych Klientów.</p>
                <p>Cieszymy się, że możemy dzielić się z Tobą naszą pasją i wiedzą na temat zdrowego snu oraz komfortu w sypialni. Zapraszamy Cię do odwiedzenia naszych sklepów i dołączenia do naszej społeczności, która ceni wyjątkowość, jakość i komfort.</p>
            </div>
        </div>
    </div>
);

export default Onas;