import React from "react";
import {logo} from "../assets";
import { Link } from "react-router-dom";
import { CiInstagram, CiFacebook, CiYoutube } from "react-icons/ci";

const Footer = () => (
  <div className="bg-[#E3DFD6] md:pt-40 pb-10">
    <div className="container mx-auto flex-col flex md:flex-row items-start justify-around md:space-x-20 p-10 md:p-0">
     
      
      <div className="flex my-5 flex-col">
        <div className="w-[100px]"><img src={logo}  alt="logo" width="100%" height="100%"></img></div>
        <Link to = "configurator"><div className="h-20 w-full cursor-default" /></Link>
      </div>
      
      <div className="flex flex-col w-full space-y-1">
        <div className="font-Bodoni my-5 pb-2 border-b border-current">
          Sleep Well
        </div>
        <p className="hover:scale-105 transition cursor-pointer"><Link to = "/materace/akissofthesunlight">A Kiss Of The Sunlight</Link></p>
        <p className="hover:scale-105 transition cursor-pointer"><Link to = "/materace/baroque">Baroque</Link></p>
        <p className="hover:scale-105 transition cursor-pointer"><Link to = "/materace/frozendreams">Frozen Dreams</Link></p>
        <p className="hover:scale-105 transition cursor-pointer"><Link to = "/materace/sleepwell">Sleep Well</Link></p>
      </div>
      
      <div className="flex flex-col w-full space-y-1">
        <div className="font-Bodoni my-5 pb-2 border-b border-current">
          Live Well
        </div>
        <p className="hover:scale-105 transition cursor-pointer"><Link to = "/lozka/gimmevolume">Gimm'e Volume</Link></p>
        <p className="hover:scale-105 transition cursor-pointer"><Link to = "/lozka/simplebutnoble">Simple but noble</Link></p>
        <p className="hover:scale-105 transition cursor-pointer"><Link to = "/lozka/inlovewithdesign">In love with Design®</Link></p>
      </div>
      
      <div className="flex flex-col w-full space-y-1">
        <div className="font-Bodoni my-5 pb-2 border-b border-current">
          Kontakt
        </div>
        <p>M&K foam Sp. z o. o.</p>
        <p>62-600 Koło, ul. Toruńska 267</p>
        <p>+48 63 26 24 300</p>
        <div className="pt-2 hover:scale-105 transition cursor-pointer">
          <Link to="odo">Ochrona danych osobowych</Link>
          </div>
        <div className="font-Bodoni pb-2 pt-5 border-b border-current">
          Social
        </div>
        <div className="flex space-x-3 text-2xl pt-5">
        <p className="hover:scale-105 transition cursor-pointer"><Link to="https://www.instagram.com/sleepwell_livewell_/"><CiInstagram /></Link></p>
        <p className="hover:scale-105 transition cursor-pointer"><Link to="https://www.facebook.com/mkfoampl"><CiFacebook /></Link></p>
        <p className="hover:scale-105 transition cursor-pointer"><Link to="https://www.youtube.com/user/160x200"><CiYoutube /></Link></p>
        </div>
      </div>
    
    </div>
    <div className="container mx-auto w-full text-left pt-20">
        <p>© 2023 M&K foam Sp. z o. o.</p>
    </div>
  </div>
);

export default Footer;
