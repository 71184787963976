import React from "react";
import { useState } from "react";
import  { logo }  from "../assets";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineInstagram,
} from "react-icons/ai";


const Navbar = () => {
  const [toggle, setToggle] = useState(true);
  const [submenu, setSubmenu] = useState(true);
  let color = 'text-white';
  
  const subMenuHandler = () => {
    setSubmenu(!submenu);
  } 

  {useLocation().pathname === "/" ? (color = 'text-white') : (color = 'text-black')};

  return (
    <div className={`fixed w-full z-50 ${color === 'text-white'} ${toggle && 'mix-blend-difference'} `}>
      <div className={`${color} container mx-auto justify-between items-center flex text-[20px] py-5 px-5 md:px-0`}>
        <Link to="/">
        <div className={`w-[40px] ${useLocation().pathname === "/" && "invert"}`}><img src={logo} width='100%' height='100%'></img>
        </div>
        </Link>
        <div className="justify-between items-center flex-1 ml-20 hidden md:flex">
          <ul className="flex uppercase space-x-20 cursor-pointer">
            <li>
              <div onClick={subMenuHandler}>kolekcja</div>
              <div
                className={`${submenu ? "hidden" : "flex"} flex-col absolute`}>
                <div onClick={subMenuHandler} className="mt-3">
                  <Link to="/materace">materace sleep well</Link>
                </div>
                <div onClick={subMenuHandler} className="mt-3">
                  <Link to="/lozka">łóżka live well</Link>
                </div>
                <span className="border-b-2 border-current"></span>
              </div>
            </li>
            <li>
            <Link to="/gallery">galeria</Link>
            </li>
            <li>
            <Link to="/salony">salony</Link>
            </li>
            <li>
              <Link to="/kontakt">kontakt</Link>
            </li>
          </ul>
          <div className="flex cursor-pointer text-2xl">
            <a href="https://www.instagram.com/sleepwell_livewell_/">
              <AiOutlineInstagram />
            </a>
          </div>
        </div>
        <div
          className="flex md:hidden cursor-pointer"
          onClick={() => setToggle((toogle) => !toggle)}>
          <div className="text-2xl">{toggle ? <AiOutlineMenu /> : ``}</div>
          <div
            className={`${
              toggle ? "hidden" : "flex"
            } justify-between absolute w-screen h-screen bg-white bg-opacity-90 left-0 top-0 text-left px-5 py-8`}>
            <ul className=" flex flex-col uppercase space-y-4 cursor-pointer text-black">
              <li>
                <Link to='/'>strona główna</Link>
              </li>
              <li>
                <Link to='/materace' className="font-bold">materace sleep well</Link>
              </li>
              <li>
                <Link to='/lozka'  className="font-bold">łóżka live well</Link>
              </li>
              <li>
              <Link to="/gallery">galeria</Link>
              </li>
              <li>
              <Link to="/salony">salony</Link>
              </li>
              <li>
                <Link to="/kontakt">kontakt</Link>
              </li>
              <li className="flex justify-left text-3xl">
                <a href="https://www.instagram.com/sleepwell_livewell_/">
                  <AiOutlineInstagram />
                </a>
              </li>
            </ul>
            <div
              className="text-black flex text-2xl"
              onClick={() => setToggle((toogle) => !toggle)}>
              <AiOutlineClose />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
