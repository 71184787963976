import React from "react";
import Divaider from "./Divaider";

export default function GaleriaSL() {
  const height = "h-svh";
  return (
    <div className={`w-full bg-color1 relative overflow-y-auto ${height}`}>
      <div className="flex w-full flex-col md:flex-row ">
        <div className="flex flex-col flex-1 md:h-full md:sticky md:top-0">
          <div className={`w-full md:sticky md:top-0 ${height}`}>
            <img
              src="/images/gallery/ILWD_galeria_1.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 h-full">
          <div className="w-full h-[700px] flex flex-col items-center justify-center">
            <div className="text-3xl text-center  xl:text-5xl xl:px-20 px-14">
              "Jakość pamięta się wiele dłużej niż cenę"
            </div>
            <div className=" text-xl text-right">Gucci</div>
          </div>
          <div className="w-full h-auto">
            <img
              src="/images/gallery/ILWD_galeria_2.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
          <Divaider />
          <div className="w-full h-auto">
            <img
              src="/images/gallery/ILWD_galeria_3.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
          <Divaider />
          <div className="w-full h-auto">
            <img
              src="/images/gallery/ILWD_galeria_4.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col md:flex-row-reverse">
        <div className="flex flex-col flex-1 md:h-full md:sticky md:top-0">
          <div className={`w-full md:sticky md:top-0 ${height}`}>
            <img
              src="/images/gallery/SBN_galeria_1.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 h-full">
          <div className="w-full h-[700px] flex flex-col items-center justify-center">
            <div className="text-3xl text-center  xl:text-5xl xl:px-20 px-14">
              "Elegancja nie polega na tym, aby być zauważonym, lecz aby być
              zapamiętanym"
            </div>
            <div className=" text-xl text-right">Giorgio Armani</div>
          </div>
          <div className="w-full h-auto">
            <img
              src="/images/gallery/SBN_galeria_2.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
          <Divaider />
          <div className="w-full h-auto">
            <img
              src="/images/gallery/SBN_galeria_3.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
          <Divaider />
          <div className="w-full h-auto">
            <img
              src="/images/gallery/SBN_galeria_4.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col md:flex-row ">
        <div className="flex flex-col flex-1 md:h-full md:sticky md:top-0">
          <div className={`w-full md:sticky md:top-0 ${height}`}>
            <img
              src="/images/gallery/GV_galeria_1.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 h-full">
          <div className="w-full h-[700px] flex flex-col items-center justify-center">
            <div className="text-3xl text-center  xl:text-5xl xl:px-20 px-14">
            „Chciałbym ludziom powiedzieć, aby otworzyli oczy i ujrzeli piękno tam, gdzie normalnie się go nie spodziewamy.”
            </div>
            <div className=" text-xl text-right">Jean Paul Gaultier</div>
          </div>
          <div className="w-full h-auto">
            <img
              src="/images/gallery/GV_galeria_2.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
          <Divaider />
          <div className="w-full h-auto">
            <img
              src="/images/gallery/GV_galeria_3.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
          <Divaider />
          <div className="w-full h-auto">
            <img
              src="/images/gallery/GV_galeria_4.jpg"
              alt="zdjecie"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
