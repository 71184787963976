import React from 'react'
import {logo} from "../assets/";

function Divaider() {
  return (
    <div className='flex h-8 items-center mt-10 mb-20 opacity-20'>
        <div className='h-[1px] bg-black w-full flex-1'></div>
        <img src={logo} alt="logo-divaider" className='h-full mx-4'/>
        <div className='h-[1px] bg-black w-full flex-1'></div>
        <div></div>
    </div>
  )
}

export default Divaider