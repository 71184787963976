import React from 'react'
import { motion } from "framer-motion";

const Punkt = ({ numer, opis }) => {
    return (
      <motion.div className="flex items-center"
      initial={{x: -50, opacity:0}}
      whileInView={{x:0, opacity:1}}
      transition={{delay: 0.3*numer, duration: 0.5}}>
        <div className="flex rounded-full bg-color2 w-[30px] h-[30px] items-center justify-center">
          {numer}
        </div>
        <div className="flex ml-3">{opis}</div>
      </motion.div>
    );
  };

export default Punkt