import React from "react";
import { pap, bq, rr, sl2, ks, fz } from "../assets";
import { Link } from "react-router-dom";

const Materace = () => {
  return (
    <>
      <div className="flex flex-col space-y-10 pt-24">
        
       

        <div className="flex flex-col-reverse md:flex-row justify-between items-center bg-[#E3DFD6]">
          <div className="flex flex-1 justify-center flex-col pb-14 md:pb-0">
            <div className="text-center font-Bodoni text-xl md:text-2xl lg:text-3xl mb-4 whitespace-nowrap">
              A Kiss Of The Sunlight
            </div>
            <div className="text-center bg-black text-white text-xl px-5 py-2 uppercase mx-auto">
            <Link to = "/materace/akissofthesunlight">więcej</Link>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <img className="w-full h-full" src={ks} alt="A kiss of the sunlight" />
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between items-center bg-[#E3DFD6]">
          <div className="flex flex-1 justify-center flex-col pb-14 md:pb-0">
            <div className="text-center font-Bodoni text-xl md:text-2xl lg:text-3xl mb-4 whitespace-nowrap">
              Baroque
            </div>
            <div className="text-center bg-black text-white text-xl px-5 py-2 uppercase mx-auto">
            <Link to = "/materace/baroque">więcej</Link>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <img className="w-full h-full" src={bq} alt="Baroque" />
          </div>
        </div>
        
        <div className="flex flex-col-reverse md:flex-row justify-between items-center bg-[#E3DFD6]">
          <div className="flex flex-1 justify-center flex-col pb-14 md:pb-0">
            <div className="text-center font-Bodoni text-xl md:text-2xl lg:text-3xl mb-4 whitespace-nowrap">
              Frozen Dreams
            </div>
            <div className="text-center bg-black text-white text-xl px-5 py-2 uppercase mx-auto">
            <Link to = "/materace/frozendreams">więcej</Link>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <img className="w-full h-full" src={fz} alt="Frozen Dreams" />
          </div>
        </div>


        
       
        <div className="flex flex-col-reverse md:flex-row justify-between items-center bg-[#E3DFD6]">
          <div className="flex flex-1 justify-center flex-col pb-14 md:pb-0">
          <div className="text-center font-Bodoni text-xl md:text-2xl lg:text-3xl mb-4 whitespace-nowrap">
              Sleep Well
            </div>
            <div className="text-center bg-black text-white text-xl px-5 py-2 uppercase mx-auto">
            <Link to = "/materace/sleepwell">więcej</Link>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <img className="w-full h-full" src={sl2} alt="SleepWeel" />
          </div>
        </div>


      </div>

      
    </>
  );
};

export default Materace;
