import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {

  const navigate = useNavigate()
useEffect(() => {

  const timer = setTimeout(() => {
    navigate('/');
  },3000)

  return () => clearTimeout(timer)

},[navigate])

  return (
    <div className="container mx-auto pt-24 my-10 flex flex-col">
      <div className="text-2xl mb-5">
        Nie znaleziono strony, której szukasz.
      </div>
      </div>
  );
}

export default NotFound;
