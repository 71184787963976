import React from 'react'
import SectionImgText from "./SectionImgText";
import Divaider from "./Divaider";
import Punkt from "./Punkt";
import Quote from "./Quote";

function Materac({FOLDER_NAME, sectionImgTextContent, desc, quote, build, name, fotoDesc="", sklad}) {
  return (
    <div className="bg-color1">
      <div className="w-full">
        <img
          src={process.env.PUBLIC_URL + FOLDER_NAME + "/main.jpg"}
          alt="zdjecie glowne"></img>
      </div>
      <div className="container mx-auto my-20 justify-center flex">
        <img
          src={process.env.PUBLIC_URL + FOLDER_NAME + "/poziomy.jpg"}
          alt="materac poziomy"></img>
      </div>

      <SectionImgText
        name={name}
        desc={sectionImgTextContent}
        FOLDER_NAME={FOLDER_NAME}
      />
      <Divaider />
      <div className="container my-32 mx-auto flex flex-col space-y-5 space-x-0 md:flex-row md:space-y-0 md:space-x-10">
        <div className="flex flex-1 rounded-xl overflow-hidden shadow-md">
          <img
            src={process.env.PUBLIC_URL + FOLDER_NAME + "/szczegol1.jpg"}
            alt="szczegol1"></img>
        </div>
        <div className="flex flex-1 rounded-xl overflow-hidden shadow-md">
          <img
            src={process.env.PUBLIC_URL + FOLDER_NAME + "/szczegol2.jpg"}
            alt="szczegol2"></img>
        </div>
        <div className="flex flex-1  rounded-xl overflow-hidden shadow-md">
          <img
            src={process.env.PUBLIC_URL + FOLDER_NAME + "/szczegol3.jpg"}
            alt="szczegol3"></img>
        </div>
      </div>
      <div className="container mx-auto my-10">
        <div className="flex text-center">
          {desc}
        </div>
      </div>

      <div className="container mx-auto">
        <div className="flex uppercase font-Bodoni md:text-2xl mt-20 pt-5 pb-2 justify-center md:justify-start items-center">
          Budowa
        </div>
        <div className="flex flex-col-reverse py-10 items-start md:flex-row">
          <div className="flex flex-col space-y-4 min-w-[300px] mb-10 md:mb-0">
            <div className="border-b border-[#000] w-full md:max-w-sm py-5 mb-5 text-center md:text-left">
              {build.desc}
            </div>
            {build.punkts.map((punkt) => {
              return <Punkt key={punkt.number} numer={punkt.number} opis={punkt.desc} />              
            })}
            {sklad && <div className="flex flex-col ms-10 pt-5 opacity-70 max-w-md">
              <p>Skład pokrowca:</p>
              <div className='ms-2 mt-2'>górna część: {sklad.gora}</div>
              <div className='ms-2 mt-2'>border: {sklad.border}</div>
            </div>}
          </div>
          <div className="flex flex-1 relative">
            <img
              src={process.env.PUBLIC_URL + FOLDER_NAME + "/wklad.jpg"}
              alt="zdjecie wkaldu"></img>
              <div className="absolute right-0 bottom-0 text-xs lg:text-base">{fotoDesc}</div>
          </div>
        </div>
        <Divaider />
        <Quote quote={quote} />
      </div>
      <div className="w-full mt-20">
        <img
          src={process.env.PUBLIC_URL + FOLDER_NAME + "/footer.jpg"}
          alt="footer"></img>
      </div>
    </div>
  )
}

export default Materac