import React, { useState, useEffect } from "react";
import { slide1, slide2, slide3 } from "../assets";

const Header = () => {
  const images = [slide1, slide2, slide3];
  const [index, setIndex] = useState(0);
  // const [slide, setSlide] = useState(images);

  useEffect(()=>{
    const lastIndex = images.length-1;
    index < 0 && setIndex(lastIndex);
    index > lastIndex && setIndex(0);
  },[index, images]);
  
  //autoplay
  useEffect(()=>{
      let interval = setInterval(()=>{
          setIndex(index + 1);
      },5000);
      return () => clearInterval(interval);
  },[index])
  


  
  return (
      <div className="w-full h-screen flex relative justify-center -z-10">

  <div className="fixed h-full w-full justify-center overflow-hidden">
      {images.map((slide, slideIndex) => {
      
        let position = "translate-x-full opacity-0"; //next slide
        
          slideIndex === index && (position = "translate-x-0 opacity-1");
         //active slide
        
          (slideIndex === index - 1 ||
            (index === 0 && slideIndex === images.length - 1)) &&
            (position = "-translate-x-full opacity-0 delay-1000");
         //prev slidenp
        return (
          <div key={slideIndex} className={`${position} absolute top-0 m-0 p-0 w-full h-full transition duration-1000 blur-0`}>
            <img className="h-full w-full object-cover" src={slide}></img>

          </div>
        );
      })}
    </div>


        <div className="text-8xl font-semibold font-Bodoni absolute top-1/4 text-white flex flex-col text-center">
          <span>Sleep Well</span>
          <span>Live Well</span>
        </div>
      </div>
  );
};

export default Header;
