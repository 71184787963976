import React from "react";
import { useState, useEffect } from "react";
import ConfiguratorSelect from "../components/ConfiguratorSelect";
import {
  getAvailableSize,
  getCategoriesList,
  isIndexInCart,
  rebuildCart,
  pricify,
  finalPrice,
  getImagesObjListFromFolder,
} from "../assets/function";
import { AiOutlineExclamationCircle } from "react-icons/ai";

function Configurator() {
 

  const currentDate = new Date().toISOString().slice(0, 10); // YYYY-MM-DD
  const dataUrl = `/dataConfigurator.json?${currentDate}`;
  
    const [data, setData] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch(dataUrl)
      .then((response) => response.json())
      .then((datas) =>{
       
        setData(datas);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  //elementy ktore zawszse sa wymagane
  const requirmentsCategoriesDefault = [
    {
      name: "cover",
    //   addons: [],
      parentName: null,
      parentIndex: null  
    },
    {
      name: "build",
    //   addons: [],
      parentName: null,
      parentIndex: null
    },
    {
      name: "bed",
    //   addons: [],
      parentName: null,
      parentIndex: null
    },
    {
      name: "textile",
    //   addons: [],
      parentName: null,
      parentIndex: null
    },
  ];

  const [requirementsCategories, setRequirementsCategories] = useState(
    requirmentsCategoriesDefault
  );
  const [cart, setCart] = useState([]);
  const [size, setSize] = useState(160);
  const availableSize = getAvailableSize(data);

  let actualAddon = null;
  let actualAddonMarginLeft = false;

  const h1Custom = "text-2xl font-Bodoni mt-5";
  return (
    <div className="container mx-auto space-y-10 py-20">
      <div className={h1Custom}>Wybierz szerokość dla materaca</div>
      <div className="flex flex-wrap justify-left">
        {availableSize.map((sizeElem, index) => (
          <div
            key={index}
            onClick={() => {
              setSize(sizeElem);
              setCart([]);
              setRequirementsCategories(requirmentsCategoriesDefault);
            }}
            className={`cursor-pointer space-x-5 border-gray-700 transition mx-2 ${
              sizeElem === size ? "border-b" : "border-b-0"
            }`}>
            {`${sizeElem} x 200`}
          </div>
        ))}
      </div>

      

      <div className={h1Custom}>Wybierz łóżko</div>
      <div className="flex flex-wrap justify-left">
        {data
          .filter((obj) => obj.category === "bed" && obj.size === size)
          .map((element, index) => (
            <div key={index}>
              <ConfiguratorSelect
                element={element}
                requirementsCategories={requirementsCategories}
                setRequirementsCategories={setRequirementsCategories}
                cart={cart}
                setCart={setCart}
                isIndexInCart={isIndexInCart(element.index, cart)}
              
              />
            </div>
          ))}
      </div>

   {/* wyswietlam tylko jak ilosc elementow w getCategoriesList() jest > 3, to wtedy oznacza, ze jakis produkt wymaga dodatkow */}
   <div
        className={`${
          getCategoriesList(requirementsCategories).length >
          requirmentsCategoriesDefault.length
            ? "block"
            : "hidden"
        } space-y-10`}>
        <div className={h1Custom}>Wybierz potrzebne elementy</div>
        <div className="flex flex-wrap justify-left">
          {data
            .filter(
              (obj) =>
                obj.category !== "cover" &&
                obj.category !== "build" &&
                obj.category !== "bed" &&
                //sprawdzam, czy dodatki sa do danego rozmiaru, albo nie maja w ogole wpisanego rozmiaru
                (obj.size === size || !obj.size) &&
                 (requirementsCategories.some((category) =>
                //    category.addons.includes(obj.category)
                    category.name === obj.category)
                 ) &&
                //sprawdzamy, czy ten obiekt ma tablice fit, a jezeli ma to czy jest w niej indeks rodzica.
                //indeksem rodzica dla nogi jest indeks lozka z koszyka

                //nastepnie ucinamy parentInxex to dlugosci elementu z tabeli fit i sprawdzamy czy on pasuje
                //w ten sposob w tblicy fit mozemy podac 81257, czyli 5 znakow. on utnie indexrodzica do 5 znakow i go porowna

                (obj.fit
                    ? obj.fit.some((elem) => {
                        const category = requirementsCategories.find((e) => e.name === obj.category);
                        // Sprawdzamy, czy category istnieje i czy category.parentIndex nie jest null
                        if (category && category.parentIndex != null) {
                          return elem.includes(
                            category.parentIndex.toString().substring(0, elem.length)
                          );
                        }
                        return false;
                      })
                    : true)
            )
            .map((element, index) => {
              if(actualAddon === element.category || actualAddon === null) {
                actualAddon = element.category;
                actualAddonMarginLeft = false;
              }
              else {
                actualAddon = element.category;
                actualAddonMarginLeft = true
              
              };
              return (
                
              <div key={index}
              className={`${actualAddonMarginLeft && "ml-20"}`}
              >
                <ConfiguratorSelect
                  element={element}
                  requirementsCategories={requirementsCategories}
                  setRequirementsCategories={setRequirementsCategories}
                  cart={cart}
                  setCart={setCart}
                  isIndexInCart={isIndexInCart(element.index, cart)}
                />
              </div>
            )})}
        </div>
      </div>


      <div className={h1Custom}>Wybierz tkaninę</div>
      <div className="font-Bodoni">MELANGERIE</div>
      <div className="flex flex-wrap justify-left">
        {[
          ...getImagesObjListFromFolder("img/Melangerie")
        ].map((element, index) => (
          <div key={index}>
            <ConfiguratorSelect
              element={{ ...element, category: "textile", index: element.name }}
              requirementsCategories={requirementsCategories}
              setRequirementsCategories={setRequirementsCategories}
              cart={cart}
              setCart={setCart}
              isIndexInCart={isIndexInCart(element.name, cart)}
            />
          </div>
        ))}
      </div>
      <div className="font-Bodoni">METIS</div>
      <div className="flex flex-wrap justify-left">
        {[
          ...getImagesObjListFromFolder("img/Metis")
        ].map((element, index) => (
          <div key={index}>
            <ConfiguratorSelect
              element={{ ...element, category: "textile", index: element.name }}
              requirementsCategories={requirementsCategories}
              setRequirementsCategories={setRequirementsCategories}
              cart={cart}
              setCart={setCart}
              isIndexInCart={isIndexInCart(element.name, cart)}
            />
          </div>
        ))}
      </div>
      <div className="font-Bodoni">TRENCH</div>
      <div className="flex flex-wrap justify-left">
        {[
          ...getImagesObjListFromFolder("img/Trench")
        ].map((element, index) => (
          <div key={index}>
            <ConfiguratorSelect
              element={{ ...element, category: "textile", index: element.name }}
              requirementsCategories={requirementsCategories}
              setRequirementsCategories={setRequirementsCategories}
              cart={cart}
              setCart={setCart}
              isIndexInCart={isIndexInCart(element.name, cart)}
            />
          </div>
        ))}
      </div>

   
      <div className={h1Custom}>Wybierz budowę materaca</div>
      <div className="flex flex-wrap justify-left">
        {data
          .filter((obj) => obj.category === "build" && obj.size === size)
          .map((element, index) => (
            <div key={index}>
              <ConfiguratorSelect
                element={element}
                requirementsCategories={requirementsCategories}
                setRequirementsCategories={setRequirementsCategories}
                cart={cart}
                setCart={setCart}
                isIndexInCart={isIndexInCart(element.index, cart)}
               
              />
            </div>
          ))}
      </div>


     
      <div className={h1Custom}>Wybierz pokrowiec materaca</div>
      <div className="flex flex-wrap justify-left">
        {data
          .filter((obj) => obj.category === "cover")
          .map((element, index) => (
            <div key={index}>
              <ConfiguratorSelect
                element={element}
                requirementsCategories={requirementsCategories}
                setRequirementsCategories={setRequirementsCategories}
                cart={cart}
                setCart={setCart}
                isIndexInCart={isIndexInCart(element.index, cart)}
              />
            </div>
          ))}
      </div>

     
     
      <div
        className={`${
          getCategoriesList(requirementsCategories).length > cart.length
            ? "block"
            : "hidden"
        }`}>
        <div className="py-10 flex align-middle space-x-2">
          <div className="text-xl animate-pulse text-red-700">
            <AiOutlineExclamationCircle />
          </div>
          <div>
            Aby wyświetlić zestawienie musisz wybrać wszykie elementy kompletu.
            Brakuje jeszcze{" "}
            {getCategoriesList(requirementsCategories).length - cart.length}{" "}
            pozycji.
          </div>
        </div>
      </div>
      {/* wyswitlam tylko jak liczba elementow w cart jest rowna ilosci wymaganych elementow w getCategoriesList(requirementsCategories) 

    */}

      <div
        className={`${
          getCategoriesList(requirementsCategories).length === cart.length
            ? "block"
            : "hidden"
        }`}>
        <div className={`${h1Custom} text-right w-full`}>Twoje zestawienie</div>
        <div className="flex flex-col w-full my-10 space-y-2">
          <div className="flex flex-1 border-b border-gray-700">
            <div className="flex flex-1 justify-end px-2 text-end text-sm lg:text-base">
              nazwa:
            </div>
            <div className="flex flex-1 justify-end px-2 text-end text-sm lg:text-base">
              index:
            </div>
            <div className="flex flex-1 justify-end px-2 text-end text-sm lg:text-base">
              cena:
            </div>
          </div>
        
          {rebuildCart(cart)
            .filter(
              (cartElement) =>
                cartElement.category !== "cover" &&
                cartElement.category !== "textile"
            )
            .map((cartElement, index) => (
              <div className="flex flex-1" key={index}>
                <div className="flex flex-1 justify-end px-2 text-end text-sm lg:text-base">
                  {cartElement.name}
                </div>
                <div className="flex flex-1 justify-end px-2 text-end text-sm lg:text-base">
                  {cartElement.index}
                </div>
                <div className="flex flex-1 justify-end px-2 text-end text-sm lg:text-base">
                  {pricify(cartElement.price)}
                </div>
              </div>
            ))}
          <div className="flex flex-1 justify-end px-2 text-xl lg:text-2xl">
            {pricify(finalPrice(cart))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configurator;
