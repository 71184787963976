import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { CgScrollH } from "react-icons/cg"

const Karuzela = ({arr}) => {

    const [width, setWidth] = useState(0 );
    const carousel = useRef();

   useEffect(()=> {
       setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth )
      //  console.log(carousel.current.scrollWidth, carousel.current.offsetWidth )
   },[])

  return (
    <div className="relative">
      {/* <div className="bg-red-500 h-full w-full absolute right-0 left-0 top-0" style ={{
clipPath: 'ellipse(50% 7% at 50% 50%)'}}></div> */}
    <motion.div ref={carousel} whileTap={{cursor:"grabbing"}} className="overflow-hidden cursor-grab relative">
      <motion.div drag="x" dragConstraints = {{right:0, left: -width}} className="flex space-x-0">
        {arr.map((image, index) => {
          return (
            <motion.div key={index} className="min-w-[700px] h-[400px]">
              <img
                className="object-cover pointer-events-none"
                src={image}
                alt={document.title + " gallery image " + index}
                style={{ height: "100%", width: "100%" }}></img>
            </motion.div>
          );
        })}
      </motion.div>
      <div className="absolute left-1/2 top-1/2 text-6xl opacity-40"><CgScrollH /></div>
    </motion.div>
    </div>
  );
};

export default Karuzela;
