
import { Link } from "react-router-dom";
import { video1, video2 } from "../assets";

const Filmy = () => (
  <div className=" bg-[#E3DFD6]">
    
    <div className="flex w-full h-[800px]">
      <div className="flex flex-col md:flex-row items-center justify-center w-full">

         <video
        autoPlay
        loop
        muted
        className="flex md:flex-1 w-full object-cover object-center h-full md:w-1/2">
        <source src={video2} type="video/mp4"></source>
      </video>

        <Link to="/materace" className="flex flex-col md:flex-1 w-full justify-center items-center p-5 h-full">
          <h1 className="text-center font-Bodoni text-7xl my-5">Sleep Well</h1>
          <p className="text-2xl text-center">KOLEKCJA MATERACY</p>
        </Link>

      </div>
    </div>

    <div className="flex w-full h-[800px]">
      <div className="flex flex-col-reverse md:flex-row items-center justify-center w-full">

        <Link to="/materace" className="flex flex-col md:flex-1 w-full justify-center items-center p-5 h-full">
          <h1 className="text-center font-Bodoni text-7xl my-5">Live Well</h1>
          <p className="text-2xl text-center">KOLEKCJA ŁÓŻEK</p>
        </Link>
         <video
        autoPlay
        loop
        muted
        className="flex w-full md:flex-1 object-cover object-center h-full md:w-1/2">
        <source src={video1} type="video/mp4"></source>
      </video>

      </div>
    </div>

  </div>
);

export default Filmy;
