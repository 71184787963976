import React from "react";
import Lozko from "../components/Lozko";

const GimmeVolume = () => {
  const sample = ["Melangerie", "Metis", "Trench"];
  const productName = "Gimme Volume";
  const FOLDER_NAME = "/images/gimmeVolume";
  const desc = "Nazwa łóżka Gimm’e Volume nie jest przypadkowa - odzwierciedla doskonale jego imponujący wygląd. Łóżko ma przyciągać wzrok i być centralnym punktem przestrzeni sypialnianej. Jego wyjątkowy design wyróżnia się na tle innych mebli. Dwie duże i miękkie poduchy, oddzielone delikatnym przeszyciem, nadają mu niepowtarzalny charakter. To nie jest zwykłe łóżko - to dzieło sztuki, które dodaje elegancji i wydobywa z niego to, co najcenniejsze - jego objętość. Nogi o wysokości 18 cm zostały wkomponowane w ramę w nietypowy sposób, co dodaje mu wyjątkowej elegancji. Gimm’e Volume to synonim jakości. Jego solidna rama jest wykonana z najlepszych materiałów, zapewniając nie tylko estetykę, ale także trwałość na lata. Tkanina użyta do wykończenia poduch jest luksusowa i wyjątkowo wytrzymała, co gwarantuje, że łóżko zachowa swój urok przez wiele sezonów.";
  const dim = {
    h: 119,
    l: 231,
    w: "153/173/193",
  };
  const features = [
    "lekko pochylony i miękki zagłówek gwarantujący komfort",
    "wbudowane nóżki metalowe 18cm", 
    "zalecany stelaż: Favorit",
    "rekomendowana tkanina: Trench oraz Melangerie",
    "możliwość zamówienia w nietypowych wymiarach"
  ]
  const carousel = [
    `${FOLDER_NAME}/colors/1.jpg`,
    `${FOLDER_NAME}/colors/2.jpg`,
    `${FOLDER_NAME}/colors/3.jpg`,
    `${FOLDER_NAME}/colors/4.jpg`,
    `${FOLDER_NAME}/colors/5.jpg`,
    `${FOLDER_NAME}/colors/6.jpg`,
  ]

  return (
    <Lozko
    FOLDER_NAME={FOLDER_NAME}
    name={productName}
    desc={desc}
    dim={dim} 
    features={features}
    sample={sample}
    carousel={carousel}/>
  );
};

export default GimmeVolume;
