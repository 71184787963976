
import React from "react";
import img1 from "../assets/img/kontaktSzkic.webp"
import logo from "../assets/SL_logo.svg"

const Kontakt = () => {
    return(
        <div className="container mx-auto pt-20 flex flex-col">
            <div className="text-5xl font-Bodoni uppercase text-center my-10">kontakt</div>
            <div className="flex justify-around flex-col md:flex-row">
                <div className="flex flex-col flex-1 px-10 md:border-r">
                    <div className="uppercase font-Bodoni my-10">adres</div>
                    <p>ul. Toruńska 267, 62-600 Koło</p>
                    <p>NIP 666-000-38-06</p>
                    <p className="mb-7">KRS 0000165485</p>
                    <div className="uppercase font-Bodoni my-10">dane kontaktowe</div>
                    <p>email: info@sl-bedrooms.com</p>
                    <p>Dział sprzedaży: 62 26 24 300</p>
                    <p>Dział reklamacji: 62 26 24 324</p>
                    <p>Dział projektowania: 62 26 24 372</p>
                    <div className="flex md:mt-[300px] mt-10">by mkfoam</div>
                </div>
                <div className="flex flex-1 flex-col px-10">
                    <div className="uppercase font-Bodoni my-10 text-2xl text-center">for professionals</div>
                    <div className="uppercase font-Bodoni my-10">kontakt</div>
                    <p>email: design@sl-bedrooms.com</p>
                    <div className="my-10">
                        <img src={img1} alt="zdjecie kontakt" />
                    </div>

                </div>
            </div>
            <div className="my-2 py-10 flex justify-center border-t">
                <img src={logo} alt="logo" className="w-[40px]" />
            </div>
        </div>
    );
}

export default Kontakt;