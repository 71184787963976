import { motion } from "framer-motion";
import { CgCheckR } from "react-icons/cg";

const SectionImgText = ({name, desc, FOLDER_NAME}) => {
    return(
        <div className="relative w-full overflow-hidden">
      <div className="container mx-auto items-center flex flex-col h-[800px] md:h-[450px] md:flex-row">
        <div className="flex flex-1 h-1/2 w-full items-center justify-center md:w-1/2 md:h-full ">
          <div className="absolute left-0 top-0 flex flex-1 h-[50%] items-center justify-center w-full md:w-1/2 md:h-full">
          <motion.img
              initial={{ opacity: 1, x: -100 }}
              whileInView={{ opacity: 1, x: 0}}
              viewport={{ once: true }}
              transition={{duration: 2}}
              src={process.env.PUBLIC_URL + FOLDER_NAME + "/fragment.jpg"}
                alt="fragment materaca"></motion.img>
          </div>
        </div>
        <div className="flex flex-1 h-1/2 w-full items-center justify-center md:w-1/2 md:h-full">
          <div className="mx-auto flex flex-col rounded-xl shadow-md bg-color2 min-h-[200px] p-10 md:max-w-sm">
            <div className="flex font-Bodoni mx-auto">MATERACE</div>
            <div className="flex font-Bodoni text-3xl mx-auto mb-5">{name}</div>
              <div className="flex mx-auto text-start flex-col space-y-2">
              {desc.map(punkt => <div key={punkt} className="flex items-start space-x-2">
                <div><CgCheckR/></div>
                <div>{punkt}</div>
                </div>)}
              </div>
            </div>
        </div>
      </div>
    </div>
    )
}

export default SectionImgText;