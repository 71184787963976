import React from "react";

function Odo() {
  return (
    <div className="container mx-auto pt-24 my-10 flex flex-col">
      <div className="text-2xl mb-5">
        Informacja w zakresie ochrony danych osobowych
      </div>
      <div className="space-y-5">
        <p>
          Działając na podstawie art. 13 i 14 Rozporządzenia Parlamentu
          Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
          sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływu takich danych (Dz. Urz. UE
          L 119 z 04.05.2016), zwanego dalej RODO, informujemy, iż
          Administratorem Danych Osobowych jest M&K Foam GmbH Sp. z o.o. z
          siedzibą w Kole (62-600) ul. Toruńska 267, z Administratorem Danych
          Osobowych można kontaktować się telefonicznie pod numerem telefonu
          (63) 26 24 300 lub e-mailowo odo@mkfoam.pl.
        </p>
        <p>
          Dane osobowe przetwarzane są w celach realizacji bieżącej zadań
          Administratora, w tym: w celach związanych z obsługą kadr i spraw
          pracowniczych, w celach realizacji dostaw, bieżącej współpracy z
          kontrahentami czy końcowymi odbiorcami, w tym również w celach obsługi
          procesu reklamacyjnego, w celach marketingowych i innych prawnie
          usprawiedliwionych celach Administratora, w celach archiwizacyjnych.
          Dane osobowe przetwarzane są wyłącznie w ww. celach i nieprzetwarzanie
          niezgodnie z tymi celami.
        </p>
        <p>
          Dane osobowe przetwarzane są w oparciu o następujące podstawy prawne:
          art. 6 ust. 1 lit. a RODO – w oparciu o zgodę na przetwarzanie danych
          osobowych, art. 6 ust. 1 lit. b RODO – w celu wykonania umowy, której
          Administrator jest stroną, art. 6 ust. 1 lit. c RODO – w celu
          realizacji obowiązku prawnego ciążącego na Administratorze, art. 6
          ust. 1 lit. f RODO – w celu realizacji prawnie uzasadnionych interesów
          realizowanych przez Administratora, którymi mogą być marketing
          bezpośredni, dochodzenie roszczeń czy inne wynikające ze specyfiki
          prowadzonego przedsiębiorstwa.
        </p>
        <p>
          W przypadku danych osobowych szczególnej kategorii podstawami prawnymi
          są: art. 9 ust. 2 lit a RODO – w oparciu o zgodę osoby, której dane
          dotyczą art. 9 ust. 2 lit b RODO – w przypadku, gdy przetwarzanie jest
          niezbędne do wykonania obowiązków m. in. w zakresie prawa pracy art. 9
          ust. 2 lit b RODO – w przypadku, gdy przetwarzanie jest niezbędne do
          ustalenia, dochodzenia lub obrony roszczeń.
        </p>
        <p>
          Dane mogą być udostępnione: wyłącznie zaufanym odbiorcom, w tym
          partnerom biznesowym, którzy przetwarzają dane na podstawie umów
          powierzenia danych osobowych, w tym m.in. podmiotom realizującym
          usługi informatyczne, prawne, księgowe czy kurierskie lub pocztowe,
          podmiot i organom upoważnionym na mocy przepisów prawa, również innym
          podmiotom, pod warunkiem uprzedniej zgody osoby, której dane dotyczą.
        </p>
        <p>
          Wszystkie wyżej wymienione podmioty zobowiązane są na podstawie umów
          powierzenia lub wprost przez przepisy o ochronie danych osobowych do
          zapewnienia odpowiednich środków technicznych i organizacyjnych w celu
          zapewnienia odpowiedniego poziomu bezpieczeństwa danych. Dane osobowe
          przetwarzane są przez okres nie dłuższy niż do realizacji celów, w
          jakich dane zostały zebrane i przetwarzane lub przez okres wynikający
          wprost z przepisów prawa. Dane osobowe zbierane są głównie od osób,
          których dane dotyczą lub w niektórych przypadkach dane mogą być
          pozyskane z ogólnie dostępnych źródeł takich jak CEiDG lub strony
          internetowe. Pozyskanie w ten sposób danych ma na celu przesłanie
          ewentualnych ofert marketingowych w celu nawiązania współpracy
          handlowej.
        </p>
        <p>
          W związku z przetwarzaniem przez M&K Foam Państwa danych osobowych
          przysługuje Państwu prawo do: żądania od Administratora dostępu do
          swoich danych osobowych, sprostowania, usunięcia lub ograniczenia
          przetwarzania danych osobowych, przenoszenia danych, sprzeciwu wobec
          przetwarzania danych, cofnięcia zgody, jeśli przetwarzanie danych
          osobowych odbywa się na podstawie zgody, wniesienia skargi do organu
          nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych.
        </p>
        <p>
          Podanie danych osobowych jest dobrowolne i w zależności od celu
          przetwarzania danych może wynikać z umowy, warunków współpracy. Bez
          podania danych osobowych osiągnięcie celu, w którym dane są
          przetwarzane, może okazać się niemożliwe. W przypadku zbierania danych
          w celu wykonania obowiązku określonego w przepisach prawa podanie
          danych jest obowiązkowe. Uwzględniając charakter, zakres, kontekst i
          cele przetwarzania oraz ryzyko naruszenia praw lub wolności osób
          fizycznych o różnym prawdopodobieństwie i wadze zagrożenia
          Administrator Danych Osobowych zobowiązuje się stosować odpowiednie
          techniczne i organizacyjne środki bezpieczeństwa w celu zapewnienia
          maksymalnego poziomu bezpieczeństwa danych osobowych.
        </p>
      </div>
      <div className="text-2xl mb-5 mt-10">
        Informacje dotyczące plików cookies.
      </div>
      <div className="space-y-5">
        <p>
          Właścicielem i operatorem serwisu jest: M&K Foam GmbH Sp. z o.o. ul.
          Toruńska 267, 62-600 Koło, NIP 666-000-38-06 Serwis wykorzystuje pliki
          cookie poprzez zamieszczanie ich w urządzeniach końcowych Użytkownika.
        </p>
        <p>
          Pliki cookies stanowią pliki tekstowe i zazwyczaj zawierają nazwę
          domeny strony lub sklepu internetowego, z którego pochodzą, czas
          przechowywania ich na urządzeniu końcowym i numer unikatowy.
        </p>
        <p>
          W jakim celu wykorzystywane są pliki coockies? 1. Utrzymania sesji
          Użytkownika (po zalogowaniu) 2. Świadczenia usług komunikacyjnych
          [newsletter, powiadomienia e-mail, itp] 3. Dostosowywania zawartości
          serwisów 4. Umożliwienia funkcjonowania niektórych mechanizmów na
          stronie, np. możliwości dodawania, usuwania i sumowania produktów w
          wirtualnym koszyku. 5. Optymalizacji korzystania ze stron
          internetowych; np. pliki cookies pozwalają rozpoznać urządzenie
          Użytkownika i odpowiednio wyświetlić stronę internetową 6. Tworzenia i
          analizowania statystyk, które pomagają zweryfikować w jaki sposób
          Użytkownicy korzystają ze stron internetowych
        </p>
        <p>
          Jak wyłączyć pliki coockies? Obsługę plików cookie można wyłączyć w
          każdej przeglądarce internetowej. Domyślnie przeglądarki internetowe
          dopuszczają pliki cookie. Użytkownik, który nie wyłączy ich obsługi
          wyraża zgodę na ich zamieszczanie. Zachęcamy do odwiedzenia strony www
          producenta przeglądarki internetowej i zapoznania się ze szczegółową
          instrukcją mówiącą o możliwości konfiguracji plików cookie.
        </p>
      </div>
      <div className="text-2xl mb-5 mt-10">Klauzula informacyjna RODO.</div>
      <div className="space-y-5">
        <p>W przypadku przesyłania przez Państwa danych osobowych do spółki M & K Foam, zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) informujemy, iż:
        </p>
        <ul className="space-y-2">
          <li className="ml-4">
            1) administratorem Państwa danych osobowych jest M&K Foam GmbH Sp. z
            o.o. z siedzibą w Kole, przy ul. Toruńskiej 267, tel. 63 26 24 300
          </li>
          <li className="ml-4">
            2) dane osobowe przetwarzane będą wyłącznie we wskazanych celach i
            nieprzetwarzane niezgodnie z tymi celami
          </li>
          <li className="ml-4">
            3) dane osobowe przechowywane są przez okres nie dłuższy niż do
            realizacji wskazanego celu lub przez okres określony w odrębnych
            przepisach
          </li>
          <li className="ml-4">
            4) w przypadku pozyskiwania danych w celu przesłania oferty
            handlowej i nawiązania ewentualnej współpracy, dane osobowe
            pozyskiwane są z ogólnie dostępnych źródeł (m. in. strona
            internetowa, CEiDG) i przetwarzane są wyłącznie w celu przesłania
            oferty, a po jej przesłaniu dane są niezwłocznie usuwane.
          </li>
          <li className="ml-4">
            5) posiadają Państwo prawo do żądania od administratora dostępu do
            swoich danych osobowych, prawo do ich sprostowania, usunięcia,
            graniczenia przetwarzania, prawo do sprzeciwu, prawo do przenoszenia
            danych oraz prawo do cofnięcia zgody, jeśli przetwarzanie danych
            osobowych odbywa się na podstawie zgody na przetwarzanie danych
            osobowych macie Państwo prawo wniesienia skargi do organu
            nadzorczego
          </li>
          <li className="ml-4">
            6) podanie danych jest dobrowolne i w zależności od celu
            przetwarzania może wynikać z umowy, warunków współpracy, wykonania
            obowiązku określonego w przepisach prawa. Bez podania danych
            osobowych osiągnięcie celu, w którym dane są przetwarzane, może być
            niemożliwe.
          </li>
        </ul>
        <p>Niniejsza informacja ma charakter informacyjny.</p>
      </div>
      <div className="text-2xl mb-5 mt-10">Informacja handlowa.</div>
      <p>Informacje zawarte na naszej stronie internetowej nie stanowią oferty handlowej w rozumieniu art. 66 § 1 Kodeksu Cywilnego. O szczegóły należy pytać w sklepach i punktach sprzedaży produktów M&K foam.</p>
    </div>
  );
}

export default Odo;
