import React from 'react'
import { RxQuote } from "react-icons/rx";

function Quote({quote, className}) {
  return (
    <div className={`flex text-center justify-center text-2xl font-light mb-20 italic ${className}`}>
    <div><RxQuote/></div>
    <div className='mx-2'>{quote}</div>
    <div><RxQuote/></div>
  </div>
  )
}
 


export default Quote