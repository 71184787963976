import React from "react";
import { updateCart, updateRequirementsCategories } from "../assets/function";

export default function ConfiguratorSelect({
  element,
  requirementsCategories,
  setRequirementsCategories,
  cart,
  setCart,
  isIndexInCart = false,
  newList,
}) {
  const handleClick = () => {
    // Aktualizacja requirementsCategories
    const updatedRequirementsCategories = updateRequirementsCategories(
      element,
      requirementsCategories
    );
    setRequirementsCategories(updatedRequirementsCategories);

    // Aktualizacja koszyka
    setCart(updateCart(element, cart, updatedRequirementsCategories));
  
    // console.log({cart});
    // console.log({requirementsCategories})
  };


  function textileName(value) {
    if(!value) return null
    
    if (value.includes('_')){
        const parts = value.split('_');
        const number = parts[0];
        const name = parts[1];
        return <div className="flex flex-col items-center leading-tight">
          <div>{name}</div>
          <div className="opacity-50">{number}</div>
        </div>
    }
    else {
      return <div>{value}</div>
    }
  }


  return (
    <div className="flex flex-col items-center space-y-1 mx-1 my-1 lg:space-y-2 lg:mx-2 lg:my-2">
      <div
        className={`flex rounded-md w-24 h-24 lg:w-44 lg:h-44 cursor-pointer overflow-hidden p-1 hover:scale-105 transition ${
          isIndexInCart ? "border-gray-700 border shadow-md" : "shadow-none"
        }`}
        onClick={handleClick}>
        {!element.photo.includes("/static/media/") ? (
          <img
            src={`images/configurator/${element.photo}`}
            alt={element.name}
            className="w-full h-full object-contain rounded-md"
          />
        ) : (
          <img
            src={element.photo}
            alt={element.name}
            className="w-full h-full object-contain rounded-md"
          />
        )}
      </div>
      <div className="text-sm lg:text-base">
        {element.category === 'textile' ? textileName(element.name) : element.name}
        </div>
    </div>
  );
}
