import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

/// wylaczenie overscrol dla Safari
// document.addEventListener('touchmove', function(event) {
//   var atTop = window.pageYOffset <= 0;
//   var atBottom = window.pageYOffset + window.innerHeight >= document.body.scrollHeight;
  
//   if (atTop || atBottom) {
//       event.preventDefault();
//   }
// }, { passive: false });
///

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
