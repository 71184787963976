import React from "react";
import Materac from "../components/Materac";

const SleepWell = () => {
  const FOLDER_NAME = "/images/sleepWell";
  const productName = "Sleep Well";
  const sectionImgTextContent = [
    "wysokość materaca ok. 30 cm",
    "materac dostępny w nietypowych rozmiarach",
    "pikowany pianką dopasowującą się do ciała",
    "możliwość skompletowania z topperem",
    "pokrowiec stanowi integralną część materaca",
  ];
  const desc =
    "To nadzwyczajne połączenie wygody i elegancji. Doskonałe rozwiązanie dla tych, którzy pragną połączyć luksusowy komfort snu z ponadprzeciętnym wzornictwem w sypialni. Materac wyróżnia się wyjątkowym pikowaniem pianką, które nadaje mu puszystego wyglądu. Delikatne i staranne pikowanie sprawia, że materac prezentuje się niezwykle elegancko i gustownie. Pokrowiec zachwyca subtelnością i minimalizmem. Jego delikatny wygląd jest niezwykle przyciągający. Nie jest zdatny do prania, ale jego trwałość i staranne wykonanie zapewniają, że będzie Ci służył przez wiele lat. Dla tych, którzy doceniają design i komfort równie mocno jak jakość snu, materac Sleep Well staje się naturalnym wyborem. To połączenie luksusu i estetyki, które wypełnią Twoją sypialnię wyjątkowym urokiem. Aby osiągnąć pełen efekt wypoczynku, rekomendujemy również przekładkę, która będzie idealnym uzupełnieniem materaca.";
  const quote =
    "Najpiękniejsze wspomnienia powstają w czasie luksusowego wypoczynku, kiedy to możemy zanurzyć się w pięknie otaczającego nas świata.";
  const fotoDesc = "Materac Sleep Well, wkład sprężynowy."
  const build = {
    desc: "Materac Sleep Well jest wyposażony w trwały wkład sprężynowy, który jest naszą rekomendacją dla tego modelu. Zapewnia on doskonałe wsparcie dla Twojego ciała i długotrwałą wygodę. Rozumiemy, że każdy klient ma swoje własne preferencje, dlatego dajemy Ci możliwość wyboru wkładu odpowiedniego dla Ciebie. Oprócz standardowego wkładu sprężynowego, możesz zdecydować się na wkład piankowy.",
    punkts: [
      {
        number: "1",
        desc: "Komfortowa pianka Lazur ",
      },
      {
        number: "2",
        desc: "Antybakteryjna i antygrzybiczna pianka Lateksoidalna",
      },
      {
        number: "3",
        desc: "Sprężyna 2000S ",
      },
      {
        number: "4",
        desc: "Odporna na odkształcenia pianka T35",
      },
    ],
  };
  const sklad = {
    gora: "66% wiskoza, 20% wełna 230g/m2, 14% bawełna 300g/m2",
    border: "63% wiskoza, 30% poliester, 7% poliamid 376g/m2"
  }

  return (
    <Materac
      FOLDER_NAME={FOLDER_NAME}
      sectionImgTextContent={sectionImgTextContent}
      name={productName}
      desc={desc}
      fotoDesc={fotoDesc}
      quote={quote}
      build={build}
      sklad={sklad}
    />
  );
};

export default SleepWell;
