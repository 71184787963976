import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header.jsx";
import Navbar from "./components/Navbar.jsx";
import Onas from "./components/Onas.jsx";
import Filmy from "./components/Filmy.jsx";
import Lozka from "./sites/Lozka.jsx";
import Footer from "./components/Footer.jsx";
import Kontakt from "./sites/Kontakt.jsx";
import Materace from "./sites/Materace.jsx";
import Salony from "./sites/Salony"
import Baroque from "./sites/Baroque";
import RichRoyal from "./sites/RichRoyal";
import SleepWell from "./sites/SleepWell";
import GimmeVolume from "./sites/GimmeVolume";
import InLoveWithDesign from "./sites/InLoveWithDesign";
import ScrollToTop from "./components/ScrollToTop";
import AKissOfTheSunLight from "./sites/AKissOfTheSunLight";
import FrozenDreams from "./sites/FrozenDreams";
import PrintsPatterns from "./sites/PrintsPatterns";
import SimpleButNoble from "./sites/SimpleButNoble";
import Odo from "./sites/Odo";
import NotFound from "./sites/NotFound";
import Configurator from "./sites/Configurator";
import Galeria from "./components/Galeria";



function App() {

  
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Header />
              <Onas />
              <Filmy />
            </>
          }
        />
        <Route path="/lozka" element={<Lozka />} />
        <Route path="/materace" element={<><Materace /></>} />
        <Route path="/lozka/gimmevolume" element={<GimmeVolume />} />
        <Route path="/lozka/inlovewithdesign" element={<InLoveWithDesign />} />
        <Route path="/materace/baroque" element={<><Baroque /></>} />
        <Route path="/materace/akissofthesunlight" element={<><AKissOfTheSunLight /></>} />
        <Route path="/lozka/simplebutnoble" element={<><SimpleButNoble /></>} />
        <Route path="/materace/frozendreams" element={<><FrozenDreams /></>} />
        <Route path="/materace/sleepwell" element={<><SleepWell /></>} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/salony" element={<Salony />} />
        <Route path="/odo" element={<Odo />} />
        <Route path="/configurator" element={<Configurator />} />

        <Route path="/gallery" element={<Galeria />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
